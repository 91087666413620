.cookieModal{
    width: 100%;
    background: #414141;
    padding: 20px;
    color: white;
    position: fixed;
    bottom: 0%;
    z-index: 1;
}

.cookiesBoxes{
    display: grid;
    grid-template-columns: 75% 25%;
    align-items: center;
    width: 100%;

    
}

.cookiesp{
    text-align: left;
    font-size: 18px;
    font: normal;
    width: 100%;
}

.cookiesButton{
    background: #44b2dd 0% 0% no-repeat padding-box;
    border-radius: 4px;
    border: none;
    font: normal Nexa-light;
    color: #ffffff;
    padding: 1em 1.5em;
    width: 35%;
    cursor: pointer;
    margin: 0px 12px;
}

@media screen and (max-width: 1200px) {

    .cookiesp{
        text-align: left;
        font-size: 15px;
    }
   

    .cookiesBoxes{
        display: grid;
        grid-template-columns: 60% 40%;
        align-items: center;
        width: 95%;
        
    }
    .cookiesButton{
        margin: 0px 5px;
    }

}

@media screen and (max-width: 800px) {

    .cookieModal{
        width: 97%;
        padding: 12px;

    }

    .cookiesp{
        text-align: center;
        font-size: 15px;
    }
    .cookiesBoxes{
        display: grid;
        grid-template-columns: 1fr;
        align-items: center;
        row-gap: 15px;
        
    }
    .cookiesButton{
        margin: 0px 5px;
    }

}