@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

html {
  scroll-behavior: smooth;
}

.App {
  text-align: center;
}

@font-face {
  font-family: "nexa-bold";
  src: url("./fonts/nexa-bold_0-webfont.woff2") format("woff2"),
       url("./fonts/nexa-bold_0-webfont.woff") format("woff");
}

@font-face {
  font-family: "nexa-light";
  src: url("./fonts/nexa-light-webfont.woff2") format("woff2"),
       url("./fonts/nexa-light-webfont.woff") format("woff");
}

body {
  font-family: 'nexa-light';
}

.content-container {
  width: 90%;
  margin: auto;
}

.heading {
  font: normal normal bold 4vw/4vw Nexa-bold;
}

.divider {
  width: 256px;
  height: 5px;
  background-color: #44b2dd66;
  margin: 25px auto;
}

p {
  font: normal normal normal 1.5vw/2.5vw Nexa-light;
  width: 75%;
  margin: auto;
}

.section-btn {
  background: #44b2dd 0% 0% no-repeat padding-box;
  border-radius: 4px;
  border: none;
  font: normal normal normal 1.5vw/22px Nexa-light;
  color: #ffffff;
  padding: 1em 1.5em;
  cursor: pointer;
}

.elite {
  color: #44b2dd;
}

@media screen and (max-width: 500px) {
  .content-container {
    width: 100%;
  }

  .heading {
    font-size: 10vw;
  }

  .section-btn {
    font-size: 4.5vw;
  }

  p {
    font-size: 5vw;
    line-height: 10vw;
  }
}